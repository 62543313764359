<template>
  <div>
    <div class="login">
      <div class="loginRegister-header">
        <div class="w w1200">
          <div class="logo">
            <div class="logoImg">
              <router-link :to="{path:'/'}" tag="a" class="logo">
                <img :src="require('@/assets/co/' + co + '/logo.png')" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="login-wrap">
          <div class="w w1200">
            <div class="login-form">
              <div class="login-box">
                <div class="tit">
                  <h3>账号登录</h3>
                  <span></span>
                </div>
                <div class="msg-wrap">
                  <div class="msg-error" v-if="msgErrorShow">{{msgErrorInfo}}</div>
                </div>
                <div class="form">
                  <div class="item">
                    <div class="item-info">
                      <i class="iconfont icon-name"></i>
                      <input type="text" v-model="mobile" class="text" value="" placeholder="客户编号" autocomplete="off"/>
                    </div>
                  </div>
                  <div class="item">
                    <div class="item-info">
                      <i class="iconfont icon-password"></i>
                      <input type="password" v-model="pwd" class="text" value="" placeholder="密码" autocomplete="off"/>
                    </div>
                  </div>
                  <div class="item">
                    <input id="remember" name="remember" type="checkbox" class="ui-checkbox" v-model="isRemember">
                    <label for="remember" class="ui-label">请保存我这次的登录信息。</label>
                  </div>
                  <div class="item item-button">
                    <el-button type="danger" :loading="btnLoading" class="btn sc-redBg-btn" @click="loginHandler">登&nbsp;&nbsp;录</el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="login-banner" :style="{background:'url('+require('@/assets/co/' + co + '/login_bg.jpg')+') center center no-repeat'}"></div>
        </div>
      </div>
    </div>
    <div class="footer user-footer">
    <div class="dsc-copyright">
      <div class="w w1200">
        <p class="footer-ecscinfo pb10">
          <router-link :to="{path:'/'}">首页</router-link>
<!--          <a href="article.php?id=2" >隐私保护</a> |-->
<!--          <a href="article.php?id=4" >联系我们</a> |-->
<!--          <a href="article.php?id=1" >免责条款</a> |-->
<!--          <a href="article.php?id=5" >公司简介</a> |-->
<!--          <a href="merchants.php"  target="_blank" >商家入驻</a> |-->
<!--          <a href="message.php" >意见反馈</a>-->
        </p>
        <p class="copyright_info">
<!--          ICP备案证书号:<a href="http://www.miit.gov.cn/" target="_blank">15002903</a> POWERED BY<a href="http://www.jzybyy.com/" target="_blank">江中亚邦医药网</a>2.0-->
        </p>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import {login,getUserInfo} from "@/api/user";
import qs from "qs";

export default {
  name: "Login",
  data() {
    return {

      msgErrorShow: false,
      msgErrorInfo:'账户名与密码不匹配，请重新输入',
      mobile: '',   // 用户名
      pwd: '',      // 密码
      isRemember:'',

      btnLoading:false
    }
  },
  created() {
    this.getCookie()
  },
  methods:{
    loginHandler(){
      if (this.mobile && this.pwd) {
        this.toLogin();
      }
    },
    // 登录
    toLogin() {
      let data = {
        account: this.mobile,
        password: this.pwd,
        // tenantId: '474709150597644357',
        // tenantId: '437830318066499653',  // 437830318066499653 华创
        tenantId: this.tenantId
      };

      let self = this

      this.btnLoading = true

      login(qs.stringify(data)).then(res => {

        console.log('########################')
        console.log(res)
        console.log('########################')

        if (res.code === 200) {
          localStorage.setItem('userToken',res.data.token);
          localStorage.setItem('userInfo',JSON.stringify(res.data.userInfo))

          let redirect = decodeURIComponent(this.$route.query.redirect || '/');
          if(redirect == '/'){
            this.$router.push({path: '/member'});
          }else{
            this.$router.push({path: redirect});
          }

          // getUserInfo().then(r => {
          //   if(r.code === 200){
          //     localStorage.setItem('userInfo',JSON.stringify(r.data.userInfo))
          //     //this.$router.push('/member')
          //
          //     let redirect = decodeURIComponent(this.$route.query.redirect || '/');
          //     if(redirect == '/'){
          //       this.$router.push({path: '/member'});
          //     }else{
          //       this.$router.push({path: redirect});
          //     }
          //   }else{
          //     this.$message.error(res.msg)
          //   }
          // })

          if (self.isRemember == true) {
            //传入账号名，密码，和保存天数3个参数
            self.setCookie(self.mobile, self.pwd, 30);
          }else{
            self.clearCookie()
          }

        } else {
          this.msgErrorShow = true;
          this.msgErrorInfo = res.msg;
        }

        self.btnLoading = false;
      }).catch(function (err) {
        console.log(err);
        self.btnLoading = false;
      });
    },
    //设置cookie
    setCookie(c_name, c_pwd, exdays) {
      var exdate = new Date(); //获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
      //字符串拼接cookie
      window.document.cookie = "userName" + "=" + c_name + ";path=/;expires=" + exdate.toGMTString();
      window.document.cookie = "userPwd" + "=" + c_pwd + ";path=/;expires=" + exdate.toGMTString();
    },
    //读取cookie
    getCookie: function() {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split('; '); //这里显示的格式需要切割一下自己可输出看下
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split('='); //再次切割
          //判断查找相对应的值
          if (arr2[0] == 'userName') {
            this.mobile = arr2[1]; //保存到保存数据的地方
            this.isRemember = true;
          } else if (arr2[0] == 'userPwd') {
            this.pwd = arr2[1];
          }
        }
      }
    },
    //清除cookie
    clearCookie: function() {
      this.setCookie("", "", -1); //修改2值都为空，天数为负1天就好了
    }
  }
}
</script>

<style scoped>

</style>
